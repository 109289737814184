import '../config/styles.css';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React from 'react';

function Login(props) {

  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  const [showMinLengthError, setShowMinLengthError] = React.useState(false);
  const [showFatalError, setShowFatalError] = React.useState(false);
  const [showEmailError, setShowEmailError] = React.useState(false);
  const [showEmailFormatError, setShowEmailFormatError] = React.useState(false);

  const login = async () => {

    if (!email) {
      setShowEmailError(true);
      setShowEmailFormatError(false);
      setShowMinLengthError(false);
      setShowError(false);
      setShowFatalError(false);
      return;
    }

    if (!validateEmail(email)) {
      setShowEmailFormatError(true);
      setShowEmailError(false);
      setShowMinLengthError(false);
      setShowError(false);
      setShowFatalError(false);
      return;
    }


    if (!password) {
      setShowError(true);
      setShowMinLengthError(false);
      setShowFatalError(false);
      setShowEmailError(false);
      setShowEmailFormatError(false);
      return;
    }

    if (password.length < 6) {
      setShowMinLengthError(true);
      setShowError(false);
      setShowFatalError(false);
      setShowEmailError(false);
      setShowEmailFormatError(false);
      return;
    }

    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setShowEmailFormatError(false);
        setShowEmailError(false);
        setShowMinLengthError(false);
        setShowError(false);
        setShowFatalError(false);
        props.onSuccess && props.onSuccess(userCredential);
      })
      .catch((error) => {
        // Invalid code
        setShowFatalError(true);
        setShowEmailFormatError(false);
        setShowEmailError(false);
        setShowError(false);
        setShowMinLengthError(false);
      });
  }

  const validateEmail = (email) => {
    var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    return pattern.test(email);
  }

  return (
    <form onSubmit={(e)=>{e.preventDefault(); login()}}>
      <input placeholder="Enter your email address here" autoComplete="email" type="email" onChange={(e) => {setEmail(e.target.value)}}/>
      <input placeholder="Enter your password here" autoComplete="current-password" type="password" onChange={(e) => {setPassword(e.target.value)}}/>
      {showError &&
        <p className="error">Your password cannot be left blank</p>
      }
      {showMinLengthError &&
        <p className="error">Your password must be at least 6 characters</p>
      }
      {showFatalError &&
        <p className="error">There was an error loggin you in. Please try again</p>
      }
      {showEmailError &&
        <p className="error">Your email cannot be left blank</p>
      }
      {showEmailFormatError &&
        <p className="error">Sorry, that doesn't seem to be a valid email address, please try again</p>
      }
      <button type="submit" className="button">Log In</button>
    </form>
  )
}

export default (Login)
