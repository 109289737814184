import '../config/styles.css';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import React from 'react';
import { createUserKeywords } from '../helpers/createUserKeywords';
import spinner from '../spinner.gif';
import Login from '../components/login';

function VerifyEmail(props) {

  const [loginRequired, setLoginRequired] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(()=>{
    if (!props?.userCredential?.currentUser) {
      setLoginRequired(true);
      setLoading(false);
    } else {
      addAccount(props.userCredential.currentUser);
    }
  },[props?.userCredential?.currentUser]);

  const addAccount = (user) => {

    const db = getFirestore();

    var userData = {
      token: 'signupVerify',
      email: user.email.toLowerCase(),
      phoneNumber: user.phoneNumber,
      uid: user.uid,
      createdAt: new Date(),
      keywords: createUserKeywords(user.phoneNumber)
    }

    //user record path
    const docRef = doc(db, 'users', user.uid)

    setDoc(docRef, userData, { merge: true })
      .then((result) => {
        setLoading(false);
        setLoginRequired(false);
      });
  }

  return (
    <div className="App">
      {loading &&
        <div className="spinner">
          <img src={spinner} alt="Loading..."/>
        </div>
      ||
      <>
      {loginRequired &&
        <>
        <h1>Please confirm your details</h1>
        <Login
          onSuccess={(userCredential)=>{addAccount(userCredential.user)}}
        />
        </>
        ||
        <>
        <h1>Thanks</h1>
        <p>Your email address has been verified and your account has been activated ready for you to log in.</p>
        </>
      }
      </>
      }
    </div>
  )
}

export default VerifyEmail;
