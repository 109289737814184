import '../config/styles.css';
import { confirmPasswordReset } from 'firebase/auth';
import React from 'react';

function PasswordReset(props) {

  const [password, setPassword] = React.useState(null);
  const [complete, setComplete] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showMinLengthError, setShowMinLengthError] = React.useState(false);
  const [showFatalError, setShowFatalError] = React.useState(false);

  const updatePassword = () => {

    if (!password) {
      setShowError(true);
      setShowMinLengthError(false);
      setShowFatalError(false);
      return;
    }

    if (password.length < 6) {
      setShowMinLengthError(true);
      setShowError(false);
      setShowFatalError(false);
      return;
    }

    confirmPasswordReset(props?.userCredential, props?.oobCode, password)
    .then(() => {
      // Success
      setComplete(true);
      setShowMinLengthError(false);
      setShowError(false);
      setShowFatalError(false);
    })
    .catch(() => {
      // Invalid code
      setShowFatalError(true);
      setShowError(false);
      setShowMinLengthError(false);
    })
  }

  return (
    <div className="App">
    <h1>Reset Password</h1>
    {complete &&
      <p>Your password has been changed. Log back into the app with your username and new password.</p>
      ||
      <>
      <p>Enter a new password below. Once you're finished, log back into the app with your username and new password.</p>
      <form onSubmit={(e)=>{e.preventDefault(); updatePassword()}}>
        <input placeholder="Enter your new password here" autoComplete="new-password" type="password" onChange={(e) => {setPassword(e.target.value)}}/>
        {showError &&
          <p className="error">Your password cannot be left blank</p>
        }
        {showMinLengthError &&
          <p className="error">Your password must be at least 6 characters</p>
        }
        {showFatalError &&
          <p className="error">There was an error updating your password. Please try again</p>
        }
        <button type="submit" className="button">Set New Password</button>
      </form>
      </>
    }
    </div>
  )
}

export default PasswordReset;
