import {createKeywords} from './createKeywords';

export function createUserKeywords(number = null, name = null) {
  if (number) {
    var numberArray = [];
    for (var i = 1; i < number.length+2; i++) {
      numberArray.push(number.slice(0,i));
    }
  }

  if (name) {
    var nameArray = createKeywords([name]);
  }

  if (number && name) {
    return numberArray.concat(nameArray);
  }

  if (number) {
    return numberArray;
  }

  if (name) {
    return nameArray;
  }

  return false;
}
