import '../config/styles.css';
import spinner from '../spinner.gif';
import React from 'react';
import { getAuth, verifyPasswordResetCode } from 'firebase/auth';
import PasswordReset from './passwordReset';
import VerifyEmail from './verifyEmail';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from '../config/firebaseConfig';

const TEST_MODE = false;

function Home(props) {

  const mode = (new URLSearchParams(window.location.search).get('mode'));
  const oobCode = (new URLSearchParams(window.location.search).get('oobCode'));
  const firebaseApp = initializeApp(firebaseConfig);
  const userCredential = getAuth(firebaseApp);

  const [loading, setLoading] = React.useState(true);
  const [validOob, setValidOob] = React.useState(false);

  React.useEffect(()=>{
    if (TEST_MODE === true || mode !== 'resetPassword') {
      setLoading(false);
      setValidOob(true);
    } else {
      verifyPasswordResetCode(userCredential, oobCode).then((email) => {
        // Display a "new password" form with the user's email address
        setLoading(false);
        setValidOob(true);
      })
      .catch((error) => {
        // Invalid code
        setLoading(false);
        setValidOob(false);
      });
    }
  },[]);

  const returnUi = (mode) => {
    switch (mode) {
      case 'verifyEmail':
        return <VerifyEmail oobCode={oobCode} userCredential={userCredential}/>
      case 'resetPassword':
        return <PasswordReset oobCode={oobCode} userCredential={userCredential}/>
      default:
        return null;
        break;
    }
  }

  return (
    <div className="App">
    {loading &&
      <div className="spinner">
        <img src={spinner} alt="Loading..."/>
      </div>
    ||
    <>
    {validOob &&
      returnUi(mode)
      ||
      <p>This link is no longer valid. If you still need to perform this action, please request to do so again in the app.</p>
    }
    </>
    }
    </div>
  )

}

export default Home;
