export function createKeywords(stringsArray) {

  if (Array.isArray(stringsArray)) {
    var allKeywords = [];
    stringsArray.forEach((item, i) => {
      if (item !== "") {
        var removePunctuation = item.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
        var keywordArray = removePunctuation.split(/[\s,]+/);
        var keywords = allKeywords.concat(keywordArray);
        allKeywords = [...new Set([...keywords,...keywordArray])]
      }
    });

    return allKeywords;

  } else {
    console.log("Argument should be an array of strings, no array passed")
  }
}
